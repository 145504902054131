import React from 'react';

import { Wrapper, SEO } from '@components';

const NotFoundPage = () => (
    <Wrapper>
        <SEO title="404: Not found" />
        <h1>NOT FOUND</h1>
        <p>Woah. Where are we? You just hit a route that doesn&#39;t exist...</p>
    </Wrapper>
);

export default NotFoundPage;
